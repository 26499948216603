import { useEffect, useState } from "react";
import { Drawer, Layout } from "antd";
import { Outlet } from "react-router-dom";

import Headers from "./components/Header";
import Sidebar from "./components/Sidebar";

import classes from "./index.module.scss";
import "../index.scss";

// The Layout component is the main component that will be used in the App.js file.
// This component will be used to wrap all the other components. There are two main components in this file,
// the Header and Sidebar components.

function Layouts() {
  const [collapsed, setCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <Layout className={classes["layout"]}>
      {windowWidth > 800 ? (
        <Sidebar collapsed={collapsed} />
      ) : (
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setOpen(false)}
          open={open}
          title={null}
          bodyStyle={{ padding: 0 }}
          width={290}
          className="sidebar-drawer"
          getContainer={() => document.getElementById("root")}
        >
          <Sidebar setOpenMenuDrawer={setOpen} />
        </Drawer>
      )}

      <Layout className="site-layout">
        <Headers
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          windowWidth={windowWidth}
          setOpenMenuDrawer={setOpen}
        />
        <Layout.Content
          dir="auto" className="site-layout-background"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "20px 20px 90px",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default Layouts;
